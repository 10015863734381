header {
  .navigationlist {
    @include mq('large') {
      display: flex;
      align-items: center;
    }
  }

  .emu-navigation {
    &__content-wrapper {
      position: fixed;
      height: 100%;
      z-index: var(--emu-common-other-z-index-header);
      transform: translateX(100%);
      transition: all var(--emu-common-other-time-duration-instant) ease-out;

      @include mq('large') {
        position: static;
        height: auto;
        transform: none;
      }

      nav {
        height: 100%;

        @include mq('medium') {
          padding-top: 80px;
        }

        @include mq('large') {
          padding: var(--emu-common-spacing-none);
          background-color: var(--emu-common-colors-transparent);
        }

        > ul {
          @include mq('medium') {
            flex-direction: column;
          }

          @include mq('large') {
            flex-direction: row;
            gap: 48px;
            padding-left: 6px;
            padding-right: 6px;
          }

          > .emu-navigation__item {
            > a {
              border-top: none;
              border-right: none;
              border-left: none;
              margin-bottom: var(--emu-common-spacing-medium);
              text-decoration: none;
              line-height: normal;
              letter-spacing: 0.64px; // from figma

              @include mq('medium') {
                padding-top: var(--emu-common-spacing-medium);
                padding-bottom: var(--emu-common-spacing-medium);
              }

              @include mq('large') {
                padding-top: var(--emu-common-spacing-none);
                padding-bottom: var(--emu-common-spacing-none);
                margin-bottom: var(--emu-common-spacing-none);
                border: none;
                leading-trim: both;
                text-edge: cap;
                font-size: 14px;
                letter-spacing: 0.56px;
              }
            }

            &--active > a {
              font-weight: var(--emu-common-font-weight-bold);
              letter-spacing: 0.84px;
            }
          }
        }
      }
      .find-a-clinic-mobile {
        li:last-of-type {
          @include mq('large') {
            display: none;
          }
        }
      }
    }
  }
}
