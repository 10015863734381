[data-component='carousel-v2'].emu-carousel {
  border: var(--emu-common-border-width-none);
  margin-bottom: 36px;

  @include mq('large') {
    margin-bottom: 20px;
  }

  .emu-carousel__item {
    border: var(--emu-common-border-width-none);
  }

  .tns-nav {
    margin-top: 24px;
    gap: var(--emu-common-spacing-small);

    > button {
      opacity: 0.3;
    }

    > button.tns-nav-active {
      opacity: 1;
    }
  }
}
