/** 
 * This component displays a form to help users find a clinic nearest to their postal code. It is present in Home, About, Benefits, FAQs and Before & After pages.
 * Design - https://www.figma.com/file/ObAIo22ang41ZEvKpUw0O9/017289_ALLSKI23_Consumer_website_AW?type=design&node-id=84-518&mode=dev
 */

.find-clinic {
  position: relative;

  &__img {
    img {
      width: 100%;
    }
  }

  &__content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 345px;
    width: 87%;
    border-radius: var(--emu-common-border-radius-small);
    padding-top: 40px;
    padding-right: 24px;
    padding-bottom: 48px;
    padding-left: 24px;

    @include mq('large') {
      max-width: 864px;
      padding-top: 35px;
      padding-right: 48px;
      padding-bottom: 48px;
      padding-left: 48px;
    }
  }

  &__title {
    letter-spacing: -0.32px;
    margin-bottom: 18px;

    @include mq('large') {
      letter-spacing: -0.64px;
      margin-bottom: 29px;
    }

    .cmp-title__text {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: var(--emu-semantic-line-heights-narrow-xl);
      font-weight: var(--emu-semantic-font-weight-500);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: var(--emu-semantic-line-heights-wide-xl);
      }
    }
  }

  &__text {
    letter-spacing: -0.48px;
    margin-bottom: 33px;

    @include mq('large') {
      letter-spacing: -0.6px;
    }

    .emphasis {
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      letter-spacing: -0.54px;

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        letter-spacing: -0.6px;
      }
    }

    u {
      text-decoration: none;
      font-family: var(--emu-semantic-font-families-mono);
      line-height: var(--emu-semantic-line-heights-narrow-medium);

      @include mq('large') {
        line-height: var(--emu-semantic-line-heights-wide-medium);
      }
    }

    br {
      display: block;

      @include mq('medium') {
        display: none;
      }
    }
  }

  &__form.emu-form.cmp-form {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    > .button {
      width: fit-content;
    }
  }

  &__input {
    width: fit-content;
    min-width: 297px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @include mq('large') {
      min-width: 345px;
    }

    label {
      position: absolute;
      top: 14px;
      left: 44px;
      font-size: var(--emu-semantic-font-sizes-narrow-small);
      line-height: normal;
      font-weight: var(--emu-semantic-font-weight-regular);
      letter-spacing: -0.32px;
      color: var(--emu-semantic-colors-primary-dark);
      cursor: text;
      transition: all var(--emu-common-other-time-duration-instant) ease-in-out;

      &.on-top {
        top: 6px;
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        letter-spacing: -0.24px;
      }
    }

    .cmp-form-text__text {
      background-color: var(--emu-semantic-colors-primary-light);
      width: 100%;
      border-width: var(--emu-common-border-width-none);
      border-radius: var(--emu-common-border-radius-xs);
      padding-top: 14px;
      padding-right: 90px;
      padding-bottom: 14px;
      padding-left: 44px;

      /* Locator icon styles */
      background-image: url('../../assets/images/locator-icon.png');
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: 12px;

      /* Typography styles */
      font-size: var(--emu-semantic-font-sizes-narrow-small);
      line-height: normal;
      letter-spacing: -0.32px;
      color: var(--emu-semantic-colors-primary-dark);
      caret-color: var(--emu-semantic-colors-primary-dark);

      &.has-value {
        padding-top: 20px;
        padding-bottom: var(--emu-common-spacing-small);
      }
    }

    &.has-error {
      .error {
        border: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-error-dark);
      }
    }
  }

  .error-message {
    color: var(--emu-semantic-colors-error-dark);
    background-color: var(--emu-semantic-colors-error-light);
    font-size: var(--emu-semantic-font-sizes-narrow-xs);
    line-height: normal;
    letter-spacing: -0.12px;
    padding-top: 3.5px; //to match figma design
    padding-bottom: 3.5px; //to match figma design
    padding-left: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-small);
    border-radius: var(--emu-common-border-radius-xs);
    margin-top: var(--emu-common-spacing-xs);
    margin-bottom: var(--emu-common-spacing-none);
  }

  &__button.emu-form-button {
    position: absolute;
    border-radius: var(--emu-common-border-radius-xxs);
    padding-top: 7px;
    padding-right: var(--emu-common-spacing-small);
    padding-bottom: 7px;
    padding-left: var(--emu-common-spacing-small);
    margin-top: 10px;
    margin-left: -82px;
    font-size: var(--emu-semantic-font-sizes-narrow-xs);
    font-weight: var(--emu-semantic-font-weight-regular);
    line-height: normal;
    letter-spacing: 0.48px;
    min-width: 70px;

    &:active,
    &:disabled {
      outline-style: none;
    }

    &:disabled {
      cursor: default;
    }
  }

  &__redirect-url {
    display: none;

    @include aem-editor-view {
      display: block;
    }
  }
}
