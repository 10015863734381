#home {
  .home {
    &__testimonial-cards {
      .aaaem-carousel__indicators {
        margin-bottom: 24px;
      }

      &-info {
        p {
          margin-bottom: 48px;
          padding-left: 24px;
          padding-right: 24px;
          color: var(--emu-semantic-colors-secondary-100);
          font-family: var(--emu-semantic-font-families-body);
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-common-line-heights-narrow-medium);

          @include mq('medium') {
            line-height: var(--emu-semantic-line-heights-wide-xs);
          }
        }
      }
    }

    &__hero-carousel-container {
      background-color: var(--emu-semantic-colors-primary-light);
    }

    &__hero-carousel {
      background-color: var(--emu-semantic-colors-primary-light);
      padding-bottom: 24px;

      @include mq('large') {
        padding-bottom: var(--emu-common-spacing-none);
      }

      .home-hero-teaser {
        .teaser-image-container {
          background-color: #c6f2f4;
          aspect-ratio: 393 / 422;

          @include mq('large') {
            aspect-ratio: 1440 / 932;
          }
        }

        .content-container {
          padding-left: 24px;
          padding-top: 24px;
          // The actual value is `24px`, use `12px` because of `line-height` calculation diff
          padding-right: 12px;

          @include mq(1023px, 'max-width') {
            position: relative;
            top: 0;
          }

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
            padding-top: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }

          .content {
            &.content-middle-right {
              margin-right: var(--emu-common-spacing-none);

              @include mq('large') {
                padding-right: 100px;
                max-width: 700px; // calc(600px + 100px);
              }

              .title-lockup {
                max-width: 100%;
                margin-left: var(--emu-common-spacing-none);
                text-align: left;
              }

              .aaaem-teaser__action-container {
                margin-left: var(--emu-common-spacing-none);
              }

              .cmp-teaser__description {
                text-align: left;
                // Set `padding-right` instead of `max-width` for fluid styling
                padding-right: 80px;
                margin-left: var(--emu-common-spacing-none);

                @include mq('large') {
                  padding-right: var(--emu-common-spacing-none);
                  position: absolute;
                  right: 96px;
                  bottom: 70px; // calc(56px + 14px);
                  max-width: 352px;
                }
              }
            }

            .aaaem-teaser__title {
              letter-spacing: -0.36px;
              font-size: var(--emu-semantic-font-sizes-narrow-xxl);
              line-height: var(--emu-semantic-line-heights-narrow-xxl);
              font-weight: var(--emu-semantic-font-weight-500);

              @include mq('large') {
                font-size: var(--emu-semantic-font-sizes-wide-xxl);
                line-height: var(--emu-semantic-line-heights-wide-xxl);
                letter-spacing: -0.72px;
              }
            }

            .aaaem-teaser__subtitle {
              font-family: var(
                --emu-semantic-font-families-helvetica-now-text-light
              );
              font-size: var(--emu-common-font-sizes-narrow-xl);
              font-weight: var(--emu-semantic-font-weight-325);
              line-height: 24px;
              letter-spacing: -0.24px;
              // The actual value is `24px`, use `20px` because of `line-height` calculation diff
              margin-bottom: 20px;
              margin-top: 20px;
              color: var(--emu-semantic-colors-primary-light);

              @include mq('large') {
                font-size: var(--emu-semantic-font-sizes-wide-large);
                line-height: var(--emu-common-line-heights-wide-xl);
                letter-spacing: -0.4px;
                // The actual value is `40px`, use `30px` because of `line-height` calculation diff
                margin-top: 30px;
                margin-bottom: var(--emu-common-spacing-none);
              }
            }

            .cmp-teaser__description {
              p {
                font-family: var(
                  --emu-semantic-font-families-helvetica-now-text-light
                );
                font-weight: var(--emu-semantic-font-weight-325);
                margin-top: var(--emu-common-spacing-none);
                margin-bottom: var(--emu-common-spacing-none);
                padding-bottom: var(--emu-common-spacing-small);
                font-size: var(--emu-common-font-sizes-narrow-medium);
                line-height: 14px;
                // override the `display: inline` inline-style applied
                display: inline-block !important;
              }
            }

            .aaaem-teaser__action-container {
              // The actual value is `24px`, use `20px` because of `line-height` calculation diff
              margin-top: 20px;
              margin-bottom: 20px;

              @include mq('large') {
                margin-top: 30px;
                margin-bottom: var(--emu-common-spacing-none);
              }

              .aaaem-teaser__action-link {
                border-radius: var(--emu-common-border-radius-xs);
                background-color: var(--emu-semantic-colors-primary-100);
                padding-left: var(--emu-common-spacing-medium);
                padding-top: var(--emu-common-spacing-medium);
                padding-right: var(--emu-common-spacing-medium);
                padding-bottom: var(--emu-common-spacing-medium);
                color: var(--emu-common-colors-white);
                text-decoration: none;
                letter-spacing: 0.64px;
                white-space: nowrap;
                // The actual value is `normal`, use `narrow-large` because of `line-height` calculation diff
                line-height: var(--emu-common-line-heights-narrow-large);
                display: flex;
                column-gap: var(--emu-common-spacing-medium);
                align-items: center;
                width: fit-content;

                @include mq('large') {
                  font-size: 18px;
                  line-height: normal;
                  letter-spacing: 0.36px;
                }

                svg {
                  width: var(--emu-common-sizing-small);

                  @include mq('large') {
                    width: 24px;
                  }
                }
              }
            }
          }

          &.text-theme-dark {
            .content {
              .aaaem-teaser__title {
                color: var(--emu-semantic-colors-primary-dark);
              }

              .cmp-teaser__description {
                p {
                  color: var(--emu-semantic-colors-secondary-300);
                }
              }
            }

            .disclaimer {
              p {
                color: var(--emu-semantic-colors-secondary-300);
              }
            }
          }

          &.disclaimer-bottom-right {
            .disclaimer {
              margin-right: 24px;

              @include mq('large') {
                margin-bottom: 56px;
                margin-left: auto;
                margin-right: 96px;
                min-width: 352px;
                text-align: left;
              }
            }
          }

          .disclaimer {
            @include mq(1023px, 'max-width') {
              position: absolute;
              top: -20px;
              right: 0;
              transform: translateY(-100%);
            }

            @include mq('large') {
              display: flex;
              column-gap: var(--emu-common-spacing-xs);
              flex-wrap: wrap;
              justify-content: flex-start;
            }

            p {
              flex-grow: 0;
              font-size: var(--emu-semantic-font-sizes-wide-xs);
              font-weight: var(--emu-semantic-font-weight-325);
              line-height: 12px;
              margin-top: var(--emu-common-spacing-none);
              margin-right: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);

              @include mq('large') {
                line-height: 14px;
              }
            }
          }
        }

        &--mobile-description-hidden {
          .content-container {
            .content {
              .cmp-teaser__description {
                @include mq(1023px, 'max-width') {
                  display: none;
                }
              }
            }
          }
        }

        &--mobile-primary-theme {
          .content-container {
            .content {
              .aaaem-teaser__title {
                @include mq(1023px, 'max-width') {
                  color: var(--emu-semantic-colors-primary-dark);
                }
              }

              .aaaem-teaser__subtitle {
                @include mq(1023px, 'max-width') {
                  color: var(--emu-semantic-colors-primary-dark);
                }
              }

              .cmp-teaser__description {
                p {
                  @include mq(1023px, 'max-width') {
                    color: var(--emu-semantic-colors-primary-200);
                  }
                }
              }
            }
          }
        }

        &--mobile-light-disclaimer {
          .content-container.text-theme-dark {
            .disclaimer {
              p {
                @include mq(1023px, 'max-width') {
                  color: var(--emu-common-colors-white);
                }
              }
            }
          }
        }

        &--mobile-dark-disclaimer {
          .content-container.text-theme-light {
            .disclaimer {
              p {
                @include mq(1023px, 'max-width') {
                  color: var(--emu-semantic-colors-secondary-300);
                }
              }
            }
          }
        }
      }

      .aaaem-carousel__indicators {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        padding-left: 24px;
        padding-right: 24px;
        justify-content: flex-start;

        @include mq('large') {
          justify-content: center;
          position: absolute;
          bottom: 48px;
          left: 50%;
          transform: translateX(-50%);
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }

    &__video-icon-and-text {
      background-color: var(--emu-semantic-colors-primary-light);
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mq('large') {
        flex-direction: row;
        column-gap: var(--emu-common-spacing-medium);
        padding-left: 96px;
        padding-top: 96px;
        padding-right: 96px;
        padding-bottom: 96px;
      }

      .home__video-thumbnail-container {
        position: relative;

        .home__video-thumbnail {
          .teaser-image-container {
            .cmp-teaser__image {
              &--mobile {
                display: block;

                @include mq('medium') {
                  display: none;
                }
              }

              &--desktop {
                display: none;

                @include mq('medium') {
                  display: block;
                  /*
                    Target width: 616px
                    Setting `width: 616px` at `medium` or `large` breaks the page
                    45vw at 1440px screen width = 648px which will be clamped to 616px
                  */
                  width: clamp(500px, 45vw, 616px);
                }
              }
            }
          }
        }

        .button {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          button {
            width: 100%;
            height: 100%;
            background-color: var(--emu-common-colors-transparent);
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 56px;
              height: auto;

              @include mq('medium') {
                width: var(--emu-common-sizing-xl);
              }
            }
          }
        }
      }

      .image-and-text {
        @include mq('large') {
          // The actual value is `24px`, use `none` because of `line-height` calculation diff
          padding-top: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-common-spacing-none);
          // The actual value is `40px`, use `large` because of `line-height` calculation diff
          row-gap: var(--emu-common-spacing-large);
        }

        @include mq('x-large') {
          padding-left: 48px;
          padding-right: 48px;
        }

        .cmp-image__image {
          width: 72px;
        }

        .cmp-title__text {
          padding-left: var(--emu-common-spacing-large);
          padding-right: var(--emu-common-spacing-large);

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
          }
        }

        .aaaem-text {
          // The actual value is `22px`, use `26px` because of `line-height` calculation diff
          line-height: 26px;

          @include mq('large') {
            line-height: var(--emu-semantic-line-heights-wide-medium);
          }

          sup {
            font-size: 70%;
          }
        }
      }
    }
  }
}
