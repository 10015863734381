.modal-wrapper {
  .modal-content {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translateY(-50%);
    border-radius: var(--emu-common-border-radius-small);
    overflow: hidden;

    button.close {
      z-index: var(--emu-common-other-z-index-layer);
      width: 56px;
      height: 56px;
      background: url('../../assets/images/modal-close.png');
      background-size: cover;
      background-position: center;
      top: 17px;
      right: 23px;
      opacity: 1;

      @include mq('large') {
        width: 38px;
        height: 38px;
        top: 20px;
        right: 24px;
      }

      span {
        display: none;
      }
    }
  }
}
