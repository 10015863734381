#clinic-details {
  padding-bottom: 90px;

  @include mq('large') {
    padding-bottom: 110px;
  }
  

  .p-clinic {
    .content-container {
      @include mq('large') {
        display: grid;
        margin-bottom: 90px;
        grid-template-columns: 50% 50%;
      }

      .icldetail {
        @include mq('large') {
          grid-column: 1 / 3;
          grid-row: 1;
        }

        &+.text {
          z-index: var(--emu-common-other-z-index-layer);

          @include mq('large') {
            grid-column: 2;
            width: 100%;
            max-width: none;
            position: static;
            grid-row: 1;
            margin-top: 160px;
            margin-left: auto;
            margin-bottom: auto;
            padding-right: 15px;
            padding-left: 15px;
          }

          .js-clinic-has-image & {
            @include mq('large') {
              margin-top: 520px;
            }

            @include mq('x-large') {
              margin-top: 614px;
            }
          }

          @include mq('xx-large') {
            margin-top: 224px;

            .js-clinic-has-image & {
              margin-top: 700px;
            }
          }

          .aaaem-text {
            margin-top: 10px;
            padding-bottom: 60px;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 60px;

            @include mq('large') {
              margin-top: var(--emu-common-spacing-none);
              padding-bottom: 40px;
              padding-left: 40px;
              padding-right: 40px;
              padding-top: 40px;
            }

            h4 {
              font-size: 27px;
              font-weight: var(--emu-common-font-weight-regular);
              letter-spacing: -0.5px;
              line-height: 35px;
            }

            p {
              font-size: var(--emu-semantic-font-sizes-narrow-small);
              font-weight: var(--emu-common-font-weight-regular);
              line-height: 30px;
              margin-bottom: 20px;
            }

            b {
              display: block;
              font-family: var(--emu-semantic-font-families-helvetica-now-text-bold);
              font-size: var(--emu-semantic-font-sizes-narrow-medium);
              font-weight: var(--emu-semantic-font-weight-500);
              line-height: 30px;

              &::before {
                content: "";
                display: block;
                margin-bottom: 30px;
                margin-top: 30px;
                height: 1px;
                width: 40px;
                background-color: var(--emu-semantic-colors-secondary-50);
                opacity: 0.6;
              }
            }
          }
        }

        &__modal {
          .aaaem-container {
            display: block;
            grid-template-columns: unset;
            margin-bottom: var(--emu-common-spacing-none);
          }

          .modal__cta--confirm {
            background-color: var(--emu-component-actions-button-color-background-primary-filled-dark);
            border-radius: 25px;
            color: var(--emu-common-colors-white);
            display: inline-block;
          }
        }
      }
    }

    .icldetail+.text .aaaem-text {
      background-color: #f4f4f4; //as per design

      @include mq('large') {
        position: relative;
        max-width: 540px;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        background-color: #f4f4f4; //as per design
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--emu-common-other-z-index-behind);

        @include mq('large') {
          top: 0;
        }
      }

      @include mq('xx-large') {
        max-width: 445px;
        padding: 40px;
      }

      h4 {
        font-size: 25px;
        line-height: 1.2;

        @include mq('xx-large') {
          font-size: 30px;
          line-height: 1.17;
        }
      }

      p {
        line-height: 30px;
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
      }

      b {
        font-family: var(--emu-semantic-font-families-helvetica-now-text-bold);
        line-height: 30px;
        font-weight: var(--emu-common-font-weight-bold);
        color: var(--emu-semantic-colors-primary-dark);
      }
    }

    .skinvive-ageverification {
      .main-close {
        display: none;
      }

      .modal-wrapper.is-open {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .modal-content {
        margin-bottom: unset;
        margin-top: unset;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;

        @include mq('large') {
          max-width: 550px;
        }
      }

      .aaaem-container {
        background-color: var(--emu-common-colors-white);
      }

      .emu-title__text {
        font-size: 35px;
        letter-spacing: -1px;
        line-height: 40px;
        text-align: center;
      }

      .aaaem-text {
        margin-bottom: 20px;
        margin-top: 20px;

        p {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: 30px;
        }
      }

      .modal-cta-container {
        flex-direction: column;
        gap: 10px;
        margin-top: 40px;

        @include mq('medium') {
          flex-direction: row;
          gap: 20px;
          justify-content: space-between;
        }

        .button {
          flex: 1 0 auto;
          max-width: 100%;
          align-items: center;
          cursor: pointer;

          .aaaem-button {
            text-align: center;
            width: 100%;

            span {
              width: 100%;
            }
          }

          &:first-child .aaaem-button {
            border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-100);
            color: var(--emu-semantic-colors-primary-dark);
            min-height: 50px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;

            &:hover {
              background-color: rgba(0, 173, 187, 0.15);
            }
          }

          &:nth-child(2),
          &:nth-child(2) .aaaem-button {
            color: var(--emu-common-colors-white);
            height: auto;
            min-height: 50px;
            align-items: center;
          }
        }
      }
    }
  }
}