.icl-feed-container {
  .clinics {
    margin-bottom: var(--emu-common-spacing-medium);
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: var(--emu-semantic-line-heights-narrow-medium);
    color: var(--emu-semantic-colors-primary-dark);
    font-family: var(--emu-semantic-font-families-body);
    font-weight: var(--emu-semantic-font-weight-500);

    &__list {
      list-style: none;
      padding-left: var(--emu-common-spacing-none);
      min-height: 100vh;
      overflow-anchor: none;

      @include aem-editor-view() {
        min-height: auto;
      }
    }

    &__show-more {
      margin-top: 40px;
      margin-bottom: 60px;
      display: flex;
      justify-content: center;
    }
  }

  .item {
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    margin-top: 40px;

    &:first-child {
      padding-top: var(--emu-common-spacing-none);
      margin-top: var(--emu-common-spacing-none);
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      left: 20px;
      margin-bottom: -20px;
      //-40px is the top margin value
      width: calc(100% - 40px);
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-50);
    }

    &--active {
      background-color: var(--emu-semantic-colors-primary-light);
    }

    &__image-container {
      padding-top: 62.85%;
      margin-bottom: 10px;
    }

    &__description {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: var(--emu-common-spacing-none);
    }

    &__distance,
    &__street,
    &__city,
    &__zip-code {
      padding-right: 5px;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__heading {
      margin-top: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
      margin-bottom: 5px;
      margin-left: var(--emu-common-spacing-none);
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: 1.44;
      letter-spacing: 0;
      font-weight: var(--emu-semantic-font-weight-500);
      color: var(--emu-semantic-colors-primary-dark);
      cursor: pointer;
    }

    &__description {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.5px;
      color: #58585a; //as per design
    }

    &__buttons-container {
      display: flex;
    }

    &__button {
      position: relative;
    }

    &__button-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__link {
      color: var(--emu-semantic-colors-primary-dark);
      cursor: pointer;
      display: inline-block;
      font-family: var(--emu-semantic-font-families-body);
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      font-weight: var(--emu-common-font-weight-regular);
      height: 50px;
      line-height: 33px;
      min-width: 50px;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      text-overflow: ellipsis;
      transition: 0.3s;
      white-space: nowrap;
    }

    &__link-show-more,
    &__link-website,
    &__link-phone {
      color: var(--emu-semantic-colors-primary-dark);
      border: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-100);
      background-color: var(--emu-common-colors-white);
      border-radius: 25px;
      padding-top: var(--emu-common-spacing-small);
      padding-right: 20px;
      padding-bottom: 12px;
      padding-left: 20px;
      margin-right: 10px;
      font-weight: var(--emu-common-font-weight-regular);

      &:hover {
        background-color: rgba(0, 173, 187, 0.15);
        border: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-100);
        color: var(--emu-semantic-colors-primary-dark);
      }
    }

    &__link-show-more {
      color: var(--emu-common-colors-white);
      background-color: var(--emu-semantic-colors-primary-dark);
      border: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-dark);

      &:hover {
        color: var(--emu-common-colors-white);
        background-color: var(--emu-semantic-colors-primary-dark);
        border: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-dark);
      }
    }

    &__link-clinic {
      border-radius: 50%;
      padding: var(--emu-common-spacing-small);
      margin-left: auto;
      border: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-primary-100);

      &:hover {
        border-color: var(--emu-semantic-colors-primary-100);
        color: var(--emu-common-colors-white);
        background-color: rgba(0, 173, 187, 0.15);
      }
    }

    header {
      position: static;
    }
  }

  .no-results {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: auto;
    padding-top: var(--emu-common-spacing-none);
    padding-right: 20px;
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: 20px;
    transform: translateY(-50%);

    &__icon-container {
      display: flex;
      justify-content: center;

      img {
        height: 100%;
        max-width: 72px;
      }
    }

    &__heading {
      text-align: center;
      margin-top: 15px;
      margin-right: var(--emu-common-spacing-none);
      margin-bottom: 15px;
      margin-left: var(--emu-common-spacing-none);
      color: var(--emu-semantic-colors-primary-dark);
      font-size: 35px;
      line-height: 40px;
    }

    &__text p {
      text-align: center;
      line-height: var(--emu-semantic-line-heights-narrow-small);
      font-size: var(--emu-semantic-font-sizes-narrow-small);
    }
  }
}

.clinics {
  &__results {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #58585a; // as per design
    text-align: center;

    &--number {
      padding-right: var(--emu-common-spacing-small);
      font-family: var(--emu-semantic-font-families-helvetica-now-text-bold);
    }

    &--location {
      color: var(--emu-semantic-colors-primary-dark);
    }

    &--reset {
      color: var(--emu-semantic-colors-primary-dark);
      border: none;
      background-color: initial;
      padding: var(--emu-common-spacing-none);
      text-decoration: underline;
      cursor: pointer;
    }

    .tabpanel__container-map & {
      @include mq('large') {
        display: none;
      }
    }
  }
}
