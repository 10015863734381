body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  margin: var(--emu-common-spacing-none);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
  
  @include mq('large') {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

sup {
  font-size: 55%;
  line-height: 0;
  position: relative;
  vertical-align: super;
  top: var(--emu-common-spacing-none);
}

b {
  font-weight: var(--emu-common-font-weight-bold);
}
