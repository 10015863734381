.company-logos {
  padding-bottom: var(--emu-common-spacing-medium);
  > div {
    display: flex;
    align-items: center;
  }
  &__skinvive-logo img {
    width: auto;
    height: 24px; // No relevant css var found
    object-fit: cover;
    /*
      The downloaded image has left content
      When using a different source, make sure to change to a suitable value     
    */
    object-position: left;
    @include mq('large') {
      height: 32px; // No relevant css var found
    }
  }
  &__allergan-aesthetics-logo img {
    width: auto;
    height: 12px; // No relevant css var found
    object-fit: cover;
    object-position: center;
    /*
      Workaround for `justify-content: space-between` on the flex container
    */
    margin-left: auto;
    @include mq('large') {
      height: 16px; // No relevant css var found
    }
  }
}
