.emu-language-nav {
  letter-spacing: 0.56px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  &[data-layout='dropdown'] {
    & .emu-language-nav {
      &__button {
        color: var(--emu-common-colors-white);
        background-color: var(--emu-semantic-colors-primary-100);
        display: flex;
        gap: var(--emu-common-sizing-xs);
        padding: var(--emu-common-sizing-xs);
        border-radius: var(--emu-common-border-radius-xxs);
        border: 2px solid var(--emu-common-colors-white);

        &:hover,
        &:focus,
        &:active {
          color: var(--emu-common-colors-white);
          background-color: var(--emu-semantic-colors-primary-100);

          & svg path {
            fill: var(--emu-common-colors-white);
          }
        }

        &[aria-expanded='true'] + ul .emu-language-nav__item-link  {
          border-top: 2px solid var(--emu-semantic-colors-primary-100);
        }

        & svg path {
          fill: var(--emu-common-colors-white);
        }
      }

      &__group {
        margin-top: 0;

        & .emu-language-nav__item-link {
          color: var(--emu-common-colors-white);
          background-color: var(--emu-semantic-colors-primary-100);
          padding: var(--emu-common-sizing-xs);
          border-radius: var(--emu-common-border-radius-xxs);
          border: 2px solid var(--emu-common-colors-white);

          &:hover,
          &:focus,
          &:active {
            color: var(--emu-common-colors-white);
            background-color: var(--emu-semantic-colors-primary-100);
          }
        }
      }
    }
  }
}
