#about {
  .image-and-text {
    row-gap: 24px;
    max-width: 728px;

    @include mq('large') {
      row-gap: 35px;
    }

    &--space-small {
      row-gap: var(--emu-common-spacing-medium);

      @include mq('large') {
        row-gap: 21px;
      }

      .image-and-text {
        &__subtitle {
          margin-bottom: var(--emu-common-spacing-xxs);

          @include mq('large') {
            margin-bottom: 5px;
          }
        }

        &__text {
          padding-left: var(--emu-common-spacing-xs);
          padding-right: var(--emu-common-spacing-xs);
        }
      }
    }

    &__paragraphs {
      p {
        .emphasis {
          font-family: var(--emu-semantic-font-families-mono);
        }
      }
    }

    &__subtitle {
      .emphasis {
        font-family: var(--emu-semantic-font-families-mono);
        line-height: 24px;

        @include mq('large') {
          line-height: 44px;
        }
      }
    }
  }

  .image-content-columns {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq('large') {
      flex-direction: row;
    }

    > .container {
      @include mq('large') {
        width: 50%;
      }
    }

    &__img {
      height: 100%;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
        max-height: 512px;

        @include mq('large') {
          max-height: none;
        }
      }

      &-container {
        height: 100%;

        > .image {
          height: 100%;
        }
      }
    }

    &__content-container {
      max-width: 720px;
      margin-left: auto;
      margin-right: auto;
    }

    &__title {
      margin-bottom: 19px;

      @include mq('large') {
        margin-bottom: 29px;
      }

      .cmp-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-narrow-xl);
        letter-spacing: -0.32px;
        font-weight: var(--emu-semantic-font-weight-500);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
          letter-spacing: -0.64px;
        }
      }
    }

    &__text {
      letter-spacing: -0.48px;

      @include mq('large') {
        letter-spacing: -0.6px;
      }

      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: var(--emu-common-spacing-none);
        }

        .emphasis {
          font-family: var(--emu-semantic-font-families-mono);
        }
      }
    }
  }

  .images-comparison {
    &-container {
      @include mq('large') {
        padding-bottom: 91px;
      }
    }

    &__title {
      margin-bottom: 27px;

      @include mq('large') {
        margin-bottom: 36px;
      }

      .cmp-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-narrow-xl);
        letter-spacing: -0.32px;
        font-weight: var(--emu-semantic-font-weight-500);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
          letter-spacing: -0.64px;
        }
      }
    }

    &__text {
      letter-spacing: -0.48px;
      margin-bottom: 24px;

      @include mq('large') {
        letter-spacing: -0.6px;
        margin-bottom: 52px; // Actual value: 56px
      }

      .emphasis {
        font-family: var(--emu-semantic-font-families-mono);
      }
    }

    &__section {
      &-wrapper {
        display: flex;
        flex-direction: column;

        @include mq('large') {
          flex-direction: row;
        }

        > .container {
          @include mq('large') {
            &:first-child {
              width: 33.3%;
            }

            &:last-child {
              flex: 1;
            }
          }
        }
      }

      &-title {
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        .cmp-title__text {
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: var(--emu-semantic-line-heights-narrow-xl);
          letter-spacing: -0.64px;
          font-weight: var(--emu-semantic-font-weight-500);
        }
      }

      &--left {
        margin-bottom: 60px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      &--right {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-left: none;
        border-top: var(--emu-common-border-width-thin) solid
          rgba(204, 223, 232, 0.4);
        padding-top: 60px;

        @include mq('large') {
          border-left: var(--emu-common-border-width-thin) solid
            rgba(204, 223, 232, 0.4);
          border-top: none;
          padding-top: var(--emu-common-spacing-none);
        }
      }

      &-logo {
        margin-bottom: -20px;
        position: relative;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-none);
        }

        img {
          max-height: 48px;
        }
      }
    }

    &__item {
      &-wrapper {
        display: flex;
        flex-direction: column;

        @include mq('large') {
          flex-direction: row;
        }

        > .container {
          @include mq('large') {
            width: 50%;
          }
        }
      }

      &-image {
        max-width: 345px;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          max-width: none;
        }

        &--shift-left {
          img {
            margin-left: -16px;

            @include mq('large') {
              margin-left: -32px;
            }
          }
        }

        &--width-sm {
          @include mq('large') {
            max-width: 94%;
            margin-top: -10px;
          }

          @include mq('xx-large') {
            max-width: 390px;
          }
        }

        &--width-bg {
          min-width: 359px;

          @include mq('medium') {
            min-width: auto;
          }

          @include mq('large') {
            max-width: 442px;
            margin-top: 30px;
          }

          @include mq('x-large') {
            margin-top: 40px;
          }
        }
      }

      &-description {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: var(--emu-common-line-heights-narrow-medium);
        margin-top: 20px;
        max-width: 345px;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          font-size: 14px;
          line-height: var(--emu-semantic-line-heights-wide-xs);
          letter-spacing: -0.14px;
          max-width: 282px;
          margin-top: var(--emu-common-spacing-medium);
        }

        @include mq('x-large') {
          max-width: 294px;
        }

        .emphasis {
          font-family: var(--emu-semantic-font-families-mono);
        }

        br {
          display: none;

          @include mq('large') {
            display: block;
          }
        }

        &--spacing-none {
          margin-top: var(--emu-common-spacing-none);

          @include mq('large') {
            margin-top: -8px;
          }
        }
      }
    }
  }

  .icons-content {
    &__title {
      margin-bottom: 19px;

      @include mq('large') {
        margin-bottom: 29px;
      }

      .cmp-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-narrow-xl);
        letter-spacing: -0.32px;
        font-weight: var(--emu-semantic-font-weight-500);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
          letter-spacing: -0.64px;
        }
      }
    }

    &__text {
      letter-spacing: -0.48px;
      margin-bottom: 27px;

      @include mq('large') {
        letter-spacing: -0.6px;
        margin-bottom: 41px;
      }

      .emphasis {
        font-family: var(--emu-semantic-font-families-mono);
      }
    }

    &__items {
      display: flex;
      flex-direction: column;
      height: 100%;

      &-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        margin-bottom: 20px;

        @include mq('large') {
          flex-direction: row;
          padding-top: var(--emu-common-spacing-large);
          padding-bottom: 26px;
          margin-bottom: 41px;
        }

        @include mq('x-large') {
          padding-left: 39px;
        }

        > .image {
          align-self: center;
        }

        > .container {
          &:last-child {
            @include mq('large') {
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: auto;
              max-width: 237px;
            }
          }
        }
      }

      &-divider {
        width: var(--emu-common-sizing-medium);
        margin-top: 26px;
        margin-bottom: var(--emu-common-spacing-large);

        @include mq('large') {
          margin-top: 40px;
          margin-bottom: var(--emu-common-spacing-none);
          margin-left: 56px;
          margin-right: 17px;
        }
      }

      &-title {
        display: flex;
        align-items: center;

        p {
          font-size: 14px;
          line-height: var(--emu-semantic-line-heights-narrow-small);
          letter-spacing: -0.28px;
          border-radius: var(--emu-common-border-radius-small);
          border: var(--emu-common-border-width-thin) solid
            var(--emu-semantic-colors-primary-100);
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 5px;
          padding-bottom: 5px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: var(--emu-common-spacing-large);

          @include mq('large') {
            line-height: var(--emu-semantic-line-heights-wide-xs);
            letter-spacing: -0.14px;
            padding-left: var(--emu-common-spacing-medium);
            padding-right: var(--emu-common-spacing-medium);
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 37px;
          }
        }
      }

      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        column-gap: var(--emu-common-spacing-small);
        row-gap: 26px;
        padding-left: 30px;
        padding-right: 30px;

        @include mq('large') {
          column-gap: var(--emu-common-spacing-none);
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: fit-content;
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);

      .image {
        display: flex;
        justify-content: center;

        .icons-content__item-icon {
          max-width: 104px;

          @include mq('large') {
            max-width: 128px;
          }
        }
      }

      &-label {
        font-size: 14px;
        line-height: var(--emu-semantic-line-heights-narrow-small);
        letter-spacing: -0.28px;

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: var(--emu-common-line-heights-wide-medium);
          letter-spacing: -0.32px;
        }

        p {
          font-family: var(
            --emu-semantic-font-families-helvetica-now-text-bold
          );
        }
      }
    }

    &__disclaimer {
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: 24px;
      letter-spacing: -0.36px;
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-medium);
        line-height: var(--emu-semantic-line-heights-wide-medium);
        letter-spacing: -0.2px;
      }
    }
  }
}
