.see-skinvive-diff {
  padding-left: 24px; // No relevant CSS var found
  padding-top: 60px; // calc(64px - 4px);
  padding-right: 24px; // No relevant CSS var found
  padding-bottom: 60px; // calc(64px - 4px);

  @include mq('large') {
    padding-left: 96px; // No relevant CSS var found
    padding-right: 96px; // No relevant CSS var found
    padding-top: 54px; // calc(64px - 10px);
    padding-bottom: 57px; // calc(64px - 7px);
  }

  &.has-bg-image {
    background-color: var(--emu-semantic-colors-primary-100);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }

  .teaser-container .content-container {
    padding-left: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-none);
    width: fit-content;
    position: static;
  }

  .aaaem-teaser__title {
    padding-right: 168px;
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    line-height: var(--emu-semantic-line-heights-narrow-xl);
    letter-spacing: -0.32px;

    @include mq('large') {
      font-size: var(--emu-common-font-sizes-wide-xxxl);
      line-height: var(--emu-common-line-heights-wide-xxxl);
      letter-spacing: -0.64px;
    }
  }

  .cmp-teaser__description {
    padding-right: 168px;
    max-width: 784px; // calc(616px + 168px);
    letter-spacing: -0.48px;
    /*
        Even tho the value is `32px` in the Figma design,
            subtract `12px` as the Figma design does not consider line height 
            of the text at the edges of the gap
    */
    margin-top: 20px; // calc(32px - 12px);
    // Same for the below value
    margin-bottom: 36px; // calc(40px - 4px);

    @include mq('medium') {
      padding-right: 300px;
    }

    @include mq('large') {
      padding-right: 168px;
      /*
        Even tho the value is `48px` in the Figma design,
            subtract `12px` as the Figma design does not consider line height 
            of the text at the edges of the gap
      */
      margin-top: 28px; // calc(48px - 12px);
      // Same for the below value
      margin-bottom: 60px; // calc(64px - 4px);
      font-size: var(--emu-semantic-font-sizes-wide-medium);
      line-height: var(--emu-semantic-line-heights-wide-medium);
      letter-spacing: -0.6px;
    }

    p {
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-none);
    }
  }

  .aaaem-teaser__action-container {
    // Add redundant selectors to increase specificity
    &.aaaem-teaser__action-container {
      &.aaaem-teaser__action-container {
        margin-top: var(--emu-common-spacing-none);
      }
    }
  }

  .aaaem-teaser__action-link {
    background-color: var(--emu-semantic-colors-primary-dark);
    color: var(--emu-common-colors-white);
    text-decoration: none;
    padding: var(--emu-common-spacing-medium);
    border-radius: var(--emu-common-border-radius-xs);
    letter-spacing: 0.64px;
    display: flex;
    column-gap: var(--emu-common-spacing-medium);
    white-space: nowrap;
    align-items: center;
    width: fit-content;
    /*
        actual value: normal
        `16px` matches the total height of the link
    */
    line-height: 16px; // No relevant CSS var found

    @include mq('large') {
      font-size: 18px; // No relevant CSS var found
      line-height: normal;
      letter-spacing: 0.36px;
    }

    &:hover {
      color: #dcdcdc; // No relevant CSS var found
    }

    svg {
      fill: currentColor;
    }
  }

  .disclaimer {
    p {
      margin-top: 12px; // calc(16px - 4px); // No relevant CSS var found
      margin-bottom: var(--emu-common-spacing-none);
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: var(--emu-common-line-heights-narrow-medium);

      @include mq('large') {
        line-height: var(--emu-semantic-line-heights-wide-xs);
      }
    }
  }
}
