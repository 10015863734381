.image-and-text {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: var(--emu-common-spacing-xl);
  padding-bottom: var(--emu-common-spacing-xl);
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: var(--emu-common-spacing-large);
  max-width: 712px; // Actual value: 688px
  margin-left: auto;
  margin-right: auto;

  @include mq('large') {
    padding-top: 96px;
    padding-bottom: 96px;
    row-gap: 40px;
  }

  &__img-container {
    display: flex;
    justify-content: center;

    img {
      width: 72px;

      @include mq('large') {
        width: 80px;
      }
    }
  }

  &__title {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--emu-semantic-font-families-heading);
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: var(--emu-semantic-line-heights-narrow-xl);
      letter-spacing: -0.32px;
      font-weight: var(--emu-semantic-font-weight-500);
      text-align: center;

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-xxxl);
        line-height: var(--emu-common-line-heights-wide-xxxl);
        letter-spacing: -0.64px;
      }
    }
  }

  &__subtitle {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-family: var(--emu-semantic-font-families-helvetica-now-text-light);
      font-size: var(--emu-common-font-sizes-narrow-xl);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      font-weight: var(--emu-semantic-font-weight-325);
      letter-spacing: -0.24px;
      
      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-large);
        line-height: var(--emu-common-line-heights-wide-xl);
        letter-spacing: -0.4px;
      }
    }
  }

  &__paragraphs {
    display: flex;
    flex-direction: column;
    row-gap: var(--emu-common-spacing-medium);
    font-size: var(--emu-semantic-font-sizes-narrow-small);
    line-height: var(--emu-semantic-line-heights-narrow-small);
    letter-spacing: -0.48px;

    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-medium);
      line-height: var(--emu-semantic-line-heights-wide-medium);
      letter-spacing: -0.6px;
    }
  }
}
