.plyr {
  --plyr-color-main: var(--emu-semantic-colors-primary-100);
  --plyr-range-thumb-height: var(
    --emu-common-sizing-none
  ); // No thumb in the Figma design
  --plyr-video-range-track-background: #00608acc;

  .plyr__video-embed {
    aspect-ratio: auto !important; // Override an inline style

    // Spell the entire className to increase specificity
    .plyr__video-embed__container {
      transform: none;
      padding-bottom: var(--emu-common-spacing-none);
      height: min(100vh, 852px);

      @include mq('large') {
        height: min(100vh, 765px);
      }
    }
  }

  .plyr__controls {
    padding-bottom: var(--emu-common-spacing-large);
    padding-left: 20px;
    padding-right: 20px;

    @include mq('large') {
      padding-left: 26px;
      padding-right: 26px;
    }
  }

  // Selects the play/pause button
  button.plyr__controls__item[data-plyr='play'] {
    margin-left: var(--emu-common-spacing-none);
    background-color: var(--emu-common-colors-transparent);
    // Use `fixed` positioning since the button cannot escape positioned ancestor
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-width: 3px;
    border-style: solid;
    border-color: var(--emu-common-colors-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    filter: drop-shadow(0px 0px 9.153px #00000040); // from Figma

    @include mq('large') {
      position: absolute;
      top: -28px;
      left: 17px;
      transform: scale(0.61);
    }

    svg {
      width: 20px;
      height: 20px;
    }

    // Selects the play button
    &[aria-label='Play'] {
      svg {
        // For optical centering, move the icon a bit by some magic number
        position: relative;
        left: 2px;
      }
    }

    // Selects the pause button
    &[aria-label='Pause'] {
      opacity: 0;
      visibility: hidden;

      @include mq('large') {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .plyr__controls__item[aria-label='Current time'] {
    position: absolute;
    left: 20px;
    top: -16px;
    margin-left: var(--emu-common-spacing-none);
    font-size: 20px;
    line-height: var(--emu-semantic-line-heights-narrow-large);
    letter-spacing: -0.6px;

    @include mq('large') {
      left: 76px;
      top: -11px;
    }
  }
}
