.p-clinics,
.p-clinic {
  .modal-wrapper {
    background-color: rgba(0, 0, 0, 0.4);

    .modal-content {
      background-color: var(--emu-common-colors-white);
      text-align: center;
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 40px;
      padding-left: 20px;
      position: absolute;
      bottom: 0;
      margin-bottom: var(--emu-common-spacing-none);
      width: 100%;
      border-radius: var(--emu-common-border-radius-none);
      top: unset;
      transform: unset;
      margin-top: 15%;

      @include mq('large') {
        max-width: 610px;
        position: relative;
        bottom: initial;
        margin-bottom: 15%;
      }

      h1 {
        font-size: 40px;
        line-height: 45px;
        color: var(--emu-semantic-colors-primary-dark);
        letter-spacing: -2px;
        margin-bottom: 30px;

        @include mq('large') {
          font-size: 50px;
          line-height: 55px;
        }
      }

      .close {
        color: var(--emu-semantic-colors-primary-dark);
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        background: unset;
        top: 5px;
        right: 20px;
        width: auto;
        height: auto;
      }

      &__header,
      &__message {
        max-width: 350px;
        margin-right: auto;
        margin-left: auto;
      }

      &__header {
        margin-top: 40px;
      }

      &__message {
        color: var(--emu-semantic-colors-primary-dark);
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-left: 20px;

        p {
          margin: var(--emu-common-spacing-none);
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: var(--emu-semantic-line-heights-narrow-medium);
        }
      }

      &__cta {
        border: var(--emu-common-border-width-thin) solid;
        border-radius: 25px;
        cursor: pointer;
        font-weight: var(--emu-semantic-font-weight-500);
        padding-bottom: 13px;
        padding-left: 27px;
        padding-right: 27px;
        padding-top: 13px;

        &-container {
          display: flex;
          justify-content: center;
          padding-bottom: 20px;
          padding-top: 20px;
        }

        &--confirm {
          background-color: var(--emu-semantic-colors-primary-dark);
          color: var(--emu-common-colors-white);
          margin-right: 10px;

          &:active,
          &:focus,
          &:hover {
            background: linear-gradient(270deg, var(--emu-semantic-colors-primary-dark) 0%, var(--emu-semantic-colors-primary-dark) 100%);
          }
        }

        &--cancel {
          border-color: var(--emu-semantic-colors-primary-dark);
          color: var(--emu-semantic-colors-primary-dark);

          &:active,
          &:focus,
          &:hover {
            background: linear-gradient(270deg, var(--emu-semantic-colors-transparent-white-300) 0%, var(--emu-semantic-colors-transparent-primary-blue-dark) 100%);
          }
        }
      }
    }
  }

  .iclfeed__modal,
  .icldetail__modal {
    .modal-content {
      >.container {
        margin-top: 30px;

        .cmp-title__text {
          margin-bottom: 20px;
          text-align: center;
        }

        .cmp-text {
          margin-bottom: 20px;
        }
      }
    }

    .modal-cta-container {
      [data-ref="close-cta"] {
        order: 1;
      }
    }

    .modal__cta--confirm {
      border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-dark);
      border-radius: 25px;
      color: var(--emu-semantic-colors-primary-dark);
      display: block;
      padding-bottom: var(--emu-common-spacing-small);
      padding-left: 27px;
      padding-right: 27px;
      padding-top: var(--emu-common-spacing-small);
    }

    .modal__cta--cancel {
      &:active,
      &:focus,
      &:hover {
        background: linear-gradient(270deg, var(--emu-semantic-colors-primary-dark) 0%, var(--emu-semantic-colors-primary-dark) 100%);
      }
    }
  }
}