.header-no-find-locator {
  header {
    .header {
      &__main-content {
        justify-content: flex-start;

        &.js-toggle-on {
          >.button {
            &:nth-child(3) {
              display: none;
            }

            &:nth-child(4) {
              display: initial;
            }

            @include mq('large') {

              &:nth-child(3),
              &:nth-child(4) {
                display: none;
              }
            }
          }
        }

        >.button {

          &:nth-child(3),
          &:nth-child(4) {
            position: absolute;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);
            width: var(--emu-common-sizing-medium);
            height: var(--emu-common-sizing-medium);
            z-index: var(--emu-common-other-z-index-cookie-banner); // more than header z-index

            @include mq('large') {
              display: none;
            }
          }

          &:nth-child(4) {
            display: none;
          }
        }
      }
    }

    .navigationlist {
      @include mq('large') {
        flex: 1;
        justify-content: center;
        margin-right: 160px;

        .emu-navigation__content-wrapper {
          width: fit-content;
        }
      }
    }
  }
}