#error {
  .error {
    &__button {
      align-items: center;
      gap: var(--emu-common-spacing-medium);
      padding-top: 12px;
      padding-bottom: 12px;
      letter-spacing: 0.64px;
      line-height: normal;

      @include mq('large') {
        padding-top: 15px;
        padding-bottom: 15px;
        letter-spacing: 0.36px;
      }

      img,
      svg {
        margin: var(--emu-common-spacing-none);
        width: var(--emu-common-sizing-small);

        @include mq('large') {
          width: 24px;
        }
      }
    }
  }
}