.clinic-header {
  header {
    .header {
      &__main-content {
        padding: 14px;

        @include mq('large') {
          padding: 24px;
          justify-content: center;
        }
      }
    }
  }
}