.aaaem-carousel {
  &__indicators {
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
  }

  &__indicator {
    opacity: 0.3;

    &.tns-nav-active {
      opacity: 1;
    }
  }
}