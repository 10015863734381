#benefits {
  .image-and-text {
    max-width: 688px; //to override the default component value (as the utility has lower specificity)
    row-gap: 21px;

    @include mq('large') {
      row-gap: 30px;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    &__paragraphs {
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  .stats {
    padding-bottom: var(--emu-common-spacing-xl);

    @include mq('medium') {
      padding-top: 83px;
      padding-bottom: 96px;
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    &__title {
      letter-spacing: -0.32px;
      margin-bottom: 19px;

      @include mq('large') {
        letter-spacing: -0.64px;
        margin-bottom: 28px;
      }

      .cmp-title__text {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-narrow-xl);
        font-weight: var(--emu-semantic-font-weight-500);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xl);
        }
      }
    }

    &__text {
      letter-spacing: -0.48px;
      margin-bottom: 42px;

      @include mq('large') {
        letter-spacing: -0.6px;
        margin-bottom: 41px;
      }

      br {
        display: block;

        @include mq('medium') {
          display: none;
        }
      }
    }

    &__container {
      display: flex;
      gap: 48px;
      justify-content: center;
      flex-direction: column;

      @include mq('x-large') {
        flex-direction: row;
        gap: 24px;
      }
    }

    &__items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      @include mq('large') {
        gap: 40px;
      }

      &-title-pill {
        border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-100);
        border-radius: var(--emu-common-border-radius-small);
        padding-top: 5.5px; //to match figma design
        padding-bottom: 5.5px; //to match figma design
        padding-left: 11px;
        padding-right: 11px;
        min-width: 192px;
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: 24px;
        letter-spacing: -0.36px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-medium);
          line-height: var(--emu-semantic-line-heights-wide-medium);
          letter-spacing: -0.2px;
          padding-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-small);
          padding-left: 12px;
          padding-right: 12px;
          min-width: 218px;
        }

        &--dark {
          border-color: var(--emu-semantic-colors-primary-dark);
        }

        sup {
          @include mq('large') {
            letter-spacing: -0.6px;
          }
        }
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include mq('medium') {
          flex-direction: row;
        }
      }

      &-separator {
        max-width: 5.3px;

        @include mq('medium') {
          max-width: 26px;
          margin-left: -2px;
        }
      }
    }

    &__item {
      display: flex;
      max-width: 245px;
      min-height: 246px;
      padding: var(--emu-common-spacing-large);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 7px;
      border-radius: 50%;

      @include mq('medium') {
        max-width: 229px;
        min-height: 229px;
        height: 229px;
        padding-top: 71px;
        padding-right: 24px;
        padding-bottom: 71px;
        padding-left: 24px;
        gap: 5px;
      }

      &-title {
        .emu-title__text {
          display: flex;
          align-items: center;
          gap: var(--emu-common-spacing-xs);
          font-size: 48px;
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
          letter-spacing: -0.48px;
          font-weight: var(--emu-semantic-font-weight-500);

          @include mq('medium') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: var(--emu-semantic-line-heights-wide-large);
            letter-spacing: -0.4px;
          }

          u {
            text-decoration: none;
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
            letter-spacing: -0.32px;

            @include mq('medium') {
              font-size: 24px;
              letter-spacing: -0.24px;
            }
          }
        }

        &--two-line {
          margin-top: -4px;

          @include mq('medium') {
            margin-top: -30px;
          }

          .emu-title__text {
            display: block;

            p {
              font-family: var(--emu-semantic-font-families-heading);

              &:first-child {
                font-size: var(--emu-semantic-font-sizes-narrow-large);
                line-height: var(--emu-semantic-line-heights-narrow-large);
                letter-spacing: -0.24px;

                @include mq('medium') {
                  line-height: var(--emu-semantic-line-heights-wide-large);
                }
              }

              &:last-child {
                display: flex;
                gap: var(--emu-common-spacing-xs);
                justify-content: center;
                margin-top: 5px;

                @include mq('medium') {
                  margin-top: -11px;
                }
              }
            }
          }
        }

        &--margin-md {
          margin-right: var(--emu-common-spacing-medium);
        }
      }

      &-text {
        font-size: 14px;
        line-height: var(--emu-semantic-line-heights-narrow-small);
        letter-spacing: -0.28px;

        @include mq('medium') {
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: var(--emu-common-line-heights-wide-medium);
          letter-spacing: -0.32px;
        }
      }
    }
  }

  .testimonial-section {
    padding-top: 43px;
    padding-bottom: 43px;

    @include mq('large') {
      padding-top: 83px;
      padding-bottom: 59px;
    }

    &__title {
      padding-left: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
      margin-bottom: var(--emu-common-spacing-large);
      letter-spacing: -0.32px;

      @include mq('large') {
        letter-spacing: -0.64px;
        margin-bottom: 59px;
      }

      .cmp-title__text {
        font-weight: var(--emu-semantic-font-weight-500);
      }
    }

    &__disclaimer {
      font-size: var(--emu-semantic-font-sizes-narrow-xs);
      line-height: var(--emu-semantic-line-heights-narrow-xs);
      padding-left: 24px;
      padding-right: 24px;

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xs);
        line-height: var(--emu-semantic-line-heights-wide-xs);
      }
    }
  }
}