#faqs {
  .faqs {
    &__about-info {
      letter-spacing: -0.48px;
      padding-left: 24px; // No CSS var found
      padding-top: var(--emu-common-spacing-xl);
      padding-right: 24px; // No CSS var found
      padding-bottom: var(--emu-common-spacing-xl);

      @include mq('large') {
        letter-spacing: -0.6px;
        padding-top: 96px; // No CSS var found
        padding-bottom: 96px; // No CSS var found
      }

      a {
        color: currentColor;
      }
    }

    &__accordions {
      .accordion {
        &:not(:first-child) {
          padding-top: var(--emu-common-spacing-large);

          @include mq('large') {
            padding-top: 48px; // No CSS var found
          }
        }

        &:not(:last-child) {
          padding-bottom: var(--emu-common-spacing-large);

          @include mq('large') {
            padding-bottom: 48px; // No CSS var found
          }
        }

        &:nth-child(even) {
          background-color: var(--emu-semantic-colors-primary-light);

          * {
            background-color: inherit;
          }
        }
      }
    }
  }

  .see-skinvive-diff {
    margin-top: var(--emu-common-spacing-xl);

    @include mq('large') {
      margin-top: 48px; // No CSS var found
    }
  }
}
