#before-and-after {
  .main-banner-teaser {
    .cmp-teaser__title {
      padding-left: var(--emu-common-spacing-xl);
      padding-right: var(--emu-common-spacing-xl);
    }
  }

  .aaaem-embed__embeddable {
    padding-bottom: var(--emu-common-spacing-none);
    height: 710px;

    @include mq('medium') {
      height: 900px; // actual from design - 751px, but that is not looking good. added 900 instead
    }
  }

  .find-clinic__content-container {
    .find-clinic__text {
      padding-left: 26px;
      padding-right: 26px;
    }
  }
}
