/** 
 * This component displays two overlapping images. It is present in About and Benefits pages.
 * Design - https://www.figma.com/file/ObAIo22ang41ZEvKpUw0O9/017289_ALLSKI23_Consumer_website_AW?type=design&node-id=180-1315&mode=dev
 */

.dual-image-overlay {
  @include mq('large') {
    display: flex;
  }

  &__img {
    position: relative;

    &--big {
      z-index: var(--emu-common-other-z-index-base);
      margin-top: -32px;

      @include mq('large') {
        margin-left: -72px;
        margin-top: var(--emu-common-spacing-none)
      }

      img {
        width: 100%;
      }
    }

    &--small {
      z-index: var(--emu-common-other-z-index-layer);
      margin-left: 48px;

      @include mq('large') {
        margin-top: 72px;
        margin-left: var(--emu-common-spacing-none);
      }

      img {
        max-width: 191px;

        @include mq('medium') {
          max-width: 47%;
        }

        @include mq('large') {
          max-width: 448px;
          width: 100%;
        }

        @include mq('x-large') {
          width: 448px;
        }
      }
    }
  }

  &--centered {
    @include mq('large') {
      align-items: center;
    }

    .dual-image-overlay__img {
      &--small {
        margin-top: var(--emu-common-spacing-none);
      }
    }
  }
}