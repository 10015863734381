.icl-tabs {
  &.aaaem-tabs {
    margin: var(--emu-common-spacing-none);
  }

  .aaaem-tabs {
    &__tablist {
      display: flex;

      @include mq('large') {
        display: none;
      }
    }

    &__tab {
      flex: 1 1 50%;
      height: 49px;
      font-size: var(--emu-semantic-font-sizes-narrow-medium);
      line-height: 1.67;
      padding: var(--emu-common-spacing-none);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: var(--emu-semantic-colors-primary-dark);

      &:first-child {
        border-right-width: var(--emu-common-border-width-thin);
        border-right-style: solid;
        border-right-color: var(--emu-semantic-colors-primary-light);
      }

      .tab-icon {
        margin-right: 10px;
        padding-top: 2px;
        height: 22px;
      }

      a {
        background-color: rgba(0, 173, 187, 0.15);
        border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-dark);
        color: var(--emu-semantic-colors-primary-dark);

        &:hover {
          background-color: rgba(0, 173, 187, 0.15);
        }
      }

      a[aria-selected=true] {
        border: none;
        background-color: var(--emu-common-colors-white);
        font-weight: var(--emu-semantic-font-weight-500);

        &:hover,
        &:visited,
        &:focus {
          color: var(--emu-semantic-colors-primary-dark);
          background-color: var(--emu-common-colors-white);
        }
      }

      a,
      a[aria-selected=true] {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }

    &__tabpanels {
      display: flex;
      flex-direction: row-reverse;
      min-height: 100vh;

      @include aem-editor-view() {
        min-height: auto;
      }

      .tabpanel__container-map .googlemaps {
        height: 100vh;
        margin: var(--emu-common-spacing-none);

        @include aem-editor-view() {
          height: auto;
        }

        @include mq('large') {
          position: fixed;
          top: 0;
          left: 390px;
          //-390px is the width of the ICL Feed (clinics list) component located on the left side of the google map.
          max-width: calc(100% - 390px);
          width: 100%;

          &.not-sticky {
            position: absolute;
            bottom: 0;
            top: initial;
            left: 0;
            max-width: 100%;
          }
        }
      }
    }

    &__tabpanel {
      flex: 1 1 auto;
      position: relative;

      @include mq('large') {
        display: block;
      }

      &:first-child {
        @include mq('large') {
          //-390px is the width of the ICL Feed (clinics list) component located on the left side of the google map.
          max-width: calc(100% - 390px);
          overflow: hidden;
        }
      }

      &:last-child {
        @include mq('large') {
          max-width: 390px;
        }
      }
    }
  }

  &.aaaem-tabs__horizontal-ui>ul:first-child li {
    margin-right: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-none);
  }
}