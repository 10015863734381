.isi-like {
  color: var(--emu-common-colors-white);
  padding-left: 24px;
  padding-top: var(--emu-common-spacing-medium);
  padding-right: 24px;
  padding-bottom: 24px;

  &__about-skinvive,
  &__footnotes-title,
  &__references-title {
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: var(--emu-common-line-heights-narrow-large);
    font-weight: var(--emu-common-font-weight-bold);

    @include mq('large') {
      line-height: var(--emu-semantic-line-heights-wide-xs);
    }

    // Selector to increase specificity
    & > p {
      font-family: var(--emu-semantic-font-families-helvetica-now-text-bold);
    }
  }

  &__about-skinvive,
  &__footnotes-title {
    padding-top: 24px;
  }

  &__references-title {
    padding-top: var(--emu-common-spacing-medium);

    @include mq('large') {
      padding-top: 20px;
    }
  }

  &__footnotes,
  &__references {
    padding-top: 18px;
    font-family: var(--emu-semantic-font-families-mono);
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: 20px;
    font-weight: var(--emu-common-font-weight-regular);

    ul,
    ol {
      margin: var(--emu-common-spacing-none);
      padding: var(--emu-common-spacing-none);
      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }
  }

  &__footnotes {
    padding-bottom: var(--emu-common-spacing-medium);
    // The default value is set to `Inter, "sans-serif"`, will need to override
    font-family: var(--emu-semantic-font-families-body);

    @include mq('large') {
      padding-bottom: 20px;
    }

    // Custom list style type for footnotes
    ul {
      list-style-type: none;

      li {
        display: flex;
        column-gap: var(--emu-common-spacing-small);

        /*
          AEM merges span tags with "emphasis" classes into one if the tags are side by side. 
          Use `b` tag for the custom marker-like characters. `b` is the most suitable tag 
          to be used here as it is semantically neutral.
        */
        b {
          min-width: 23px;
          font-weight: var(--emu-common-font-weight-regular);
        }

        sup {
          // The design does not mention a value but `65%` almost matches the design
          font-size: 65%;
        }
      }
    }
  }

  &__references {
    ol {
      // The default value is set to `Inter, "sans-serif"`, will need to override
      font-family: var(--emu-semantic-font-families-body);
      padding-left: 14px;

      li {
        padding-left: 14px;
      }
    }
  }

  &__more-info-and-directions {
    padding-top: 18px;
    font-size: var(--emu-common-font-sizes-narrow-medium);
    line-height: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 14px;

    .isi-like__more-info {
      display: flex;
      flex-direction: column;
      row-gap: 14px;
    }
  }

  &__directions-healthcare-pros {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  .boxed {
    border: var(--emu-common-border-width-thin) solid
      var(--emu-semantic-colors-primary-50);
    padding: var(--emu-common-spacing-medium);
  }
}
