.button {
    text-align: center;

    // Adding redundant selectors to increase specificity
    .emu-button-v2.emu-button:not(a).emu-button {
        &__primary-outline {
            font-size: 14px;
            line-height: 20.6px;
            padding-top: 14px;
            padding-right: 12px;
            padding-bottom: 14px;
            padding-left: 12px;
        }
    }
}
