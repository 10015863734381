/** 
 * This component displays customer testimonials in a card layout. The testimonial cards are displayed in a carousel which is present in Home and Benefits pages.
 * Design - https://www.figma.com/file/ObAIo22ang41ZEvKpUw0O9/017289_ALLSKI23_Consumer_website_AW?type=design&node-id=302-6906&mode=dev
 */

.testimonial-card {
  display: flex;
  border-radius: var(--emu-semantic-border-radius-small);
  overflow: hidden;
  max-width: 357px;
  height: 100%;

  @include mq('large') {
    max-width: 688px;
  }

  &__img {
    height: 100%;
    min-height: 278px;

    @include mq('large') {
      min-height: 374px;
    }

    &-container {
      height: 100%;

      > .image {
        height: 100%;

        &:has(.desktop-view) {
          @include mq('1023px', 'max-width') {
            display: none;
          }
        }

        &:has(.tablet-mobile-view) {
          @include mq('large') {
            display: none;
          }
        }
      }
    }

    img {
      max-width: 178.5px; //to match figma design
      height: 100%;
      object-fit: cover;

      @include mq('large') {
        max-width: 267px;
      }
    }
  }

  &__content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 18px;
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: 10px;
    padding-left: var(--emu-common-spacing-medium);
    gap: 10px;

    @include mq('large') {
      padding-top: 31px;
      padding-right: 24px;
      padding-bottom: 17px;
      padding-left: 24px;
      gap: 15px;
    }

    &--padding-sm {
      padding-right: 12px;

      @include mq('large') {
        padding-right: 24px;
      }
    }

    > .text {
      margin-top: auto;
    }
  }

  &__title {
    letter-spacing: -0.24px;

    @include mq('large') {
      letter-spacing: -0.4px;
    }

    .cmp-title__text {
      font-weight: var(--emu-semantic-font-weight-500);
    }
  }

  &__quote {
    max-width: 25px;

    @include mq('large') {
      max-width: 50px;
    }
  }

  &__text {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: 24px;
    letter-spacing: -0.36px;

    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-small);
      line-height: var(--emu-semantic-line-heights-wide-small);
      letter-spacing: -0.2px;
    }
  }
}
