// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// content related util classes. Used to center buttons mostly.
.content--centered {
  text-align: center;
}

// background color related classes
.bg--primary-light {
  background-color: var(--emu-semantic-colors-primary-light);
}

.bg--primary-dark {
  background-color: var(--emu-semantic-colors-primary-dark);
}

.bg--primary-50 {
  background-color: var(--emu-semantic-colors-primary-50);
}

.bg--primary-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.bg--secondary-200 {
  background-color: var(--emu-semantic-colors-secondary-200);
}

.bg--error-light {
  background-color: var(--emu-semantic-colors-error-light);
}

// text color related classes
[data-component='title'],
[data-component='text'] {
  &.text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.text--color-black {
    color: var(--emu-common-colors-black);
  }

  &.text--color-primary-light {
    color: var(--emu-semantic-colors-primary-light);
  }

  &.text--color-primary-dark {
    color: var(--emu-semantic-colors-primary-dark);
  }

  &.text--color-primary-50 {
    color: var(--emu-semantic-colors-primary-50);
  }

  &.text--color-primary-100 {
    color: var(--emu-semantic-colors-primary-100);
  }

  &.text--color-secondary-light {
    color: var(--emu-semantic-colors-secondary-light);
  }

  &.text--color-secondary-dark {
    color: var(--emu-semantic-colors-secondary-dark);
  }

  &.text--color-secondary-300 {
    color: var(--emu-semantic-colors-secondary-300);
  }

  &.text--color-secondary-100 {
    color: var(--emu-semantic-colors-secondary-100);
  }
}

.container-large-centered {
  // Selector added to increase the specificity
  &.aaaem-container {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: auto;
    margin-right: auto;
  }
}

.mobile-centered {
  @include mq(1023px, 'max-width') {
    /*
      This utility is used along with some other utilities or `.scss` styles. 
      In those cases, this utility's `max-width` should be applied.
    */
    max-width: 530px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.main-banner-teaser {
  min-height: 170px;
  aspect-ratio: 3 / 5;

  @include mq('large') {
    min-height: 280px;
    aspect-ratio: 47 / 25;
  }
}

.hide-find-a-clinic-cta {
  header .header__main-content > .button:nth-child(3) .emu-button {
    visibility: hidden;
    pointer-events: none;
  }
}

.content-centered {
  max-width: 688px;
  margin-left: auto;
  margin-right: auto;
}

.container-padding-y {
  &.aaaem-container {
    padding-top: 57px;
    padding-bottom: 57px;

    @include mq('large') {
      padding-top: 86px;
      padding-bottom: 86px;
    }
  }
}

.container-padding-x {
  &.aaaem-container {
    padding-left: 24px;
    padding-right: 24px;

    @include mq('large') {
      padding-left: 56px;
      padding-right: 56px;
    }

    @include mq('x-large') {
      padding-left: 96px;
      padding-right: 96px;
    }
  }
}

.container-flex {
  display: flex;
  justify-content: center;
  align-items: center;

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &--centered {
    &.aaaem-container {
      padding-top: var(--emu-common-spacing-xl);
      padding-bottom: var(--emu-common-spacing-xl);
      padding-left: 24px;
      padding-right: 24px;
      min-height: 702px;
      max-width: 580px;

      @include mq('large') {
        padding-top: 96px;
        padding-bottom: 96px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        min-height: 932px;
        max-width: 704px;
      }
    }
  }
}

.section {
  &__container {
    &.aaaem-container {
      max-width: 600px;

      @include mq('large') {
        max-width: 860px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }
    }
  }

  &__title {
    margin-bottom: 19px;

    @include mq('large') {
      margin-bottom: 29px;
    }

    .cmp-title__text {
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
      line-height: var(--emu-semantic-line-heights-narrow-xl);
      letter-spacing: -0.32px;
      font-weight: var(--emu-semantic-font-weight-500);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
        line-height: var(--emu-semantic-line-heights-wide-xl);
        letter-spacing: -0.64px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 20px;

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-large);
    }

    .cmp-title__text {
      font-size: var(--emu-semantic-font-sizes-narrow-large);
      line-height: var(--emu-semantic-line-heights-narrow-large);
      letter-spacing: -0.24px;
      font-weight: var(--emu-semantic-font-weight-500);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-large);
        line-height: var(--emu-semantic-line-heights-wide-large);
        letter-spacing: -0.4px;
      }
    }
  }

  &__text {
    font-size: var(--emu-semantic-font-sizes-narrow-small);
    line-height: var(--emu-semantic-line-heights-narrow-small);
    letter-spacing: -0.48px;
    margin-bottom: 27px;

    @include mq('large') {
      font-size: var(--emu-semantic-font-sizes-wide-medium);
      line-height: var(--emu-semantic-line-heights-wide-medium);
      letter-spacing: -0.6px;
      margin-bottom: 41px;
    }

    &--mb-none {
      margin-bottom: var(--emu-common-spacing-none);
    }

    // specificity needed to override default styles
    &[data-component='text'] p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: var(--emu-common-spacing-none);
      }

      b {
        font-family: var(--emu-semantic-font-families-helvetica-now-text-bold);
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    .emphasis {
      font-family: var(--emu-semantic-font-families-mono);
    }
  }
}

.hide-header {
  .header header {
    display: none;
  }
}

.hide {
  display: none !important;

  @include aem-editor-view {
    display: block !important;
  }
}

// when there is a button component with hide class in it, hiding the whole button
.button {
  &:has(.hide) {
    display: none !important;

    @include aem-editor-view {
      display: block !important;
    }
  }
}
