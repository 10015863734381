.tabpanel__container-map {
  .options {
    position: absolute;
    z-index: var(--emu-common-other-z-index-tabs);
    left: 0;
    top: 80px;
    width: 100%;
    display: flex;
    justify-content: center;

    @include mq('large') {
      top: 10px;
    }
  }
}

.cmp-form-options--checkbox {
  position: relative;
  left: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 7px;
  background-color: var(--emu-common-colors-white);
  border-radius: var(--emu-common-border-radius-small);
  box-shadow: 0 1px 4px 0 var(--emu-semantic-colors-secondary-50);
}

.cmp-form-options__field--checkbox:not(:checked)+span:after {
  opacity: 0;
  transform: scale(0);
}

.cmp-form-options__field--checkbox:checked,
.cmp-form-options__field--checkbox:not(:checked) {
  height: 18px;
  left: 4px;
  opacity: 1;
  position: absolute;
  top: 4px;
  width: 18px;
}

.cmp-form-options__field--checkbox:checked+span,
.cmp-form-options__field--checkbox:not(:checked)+span {
  color: var(--emu-semantic-colors-primary-dark);
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 20px;
  padding-left: 28px;
  position: relative;
}

.cmp-form-options__field--checkbox+span:before,
.cmp-form-options__field--checkbox:not(:checked)+span:before {
  background: var(--emu-common-colors-white);
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-dark);
  border-radius: var(--emu-common-border-radius-xs);
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.cmp-form-options__field--checkbox:checked+span:after,
.cmp-form-options__field--checkbox:not(:checked)+span:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cpath d='M4 10.5 8.5 15 16 6' stroke='%2300608a' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 14px;
  background-color: var(--emu-common-colors-transparent);
  border-radius: var(--emu-common-border-radius-xs);
  content: "";
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all .2s ease;
  width: 20px;
}