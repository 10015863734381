.footer {
  .emu-navigation__content-wrapper {
    border-top: var(--emu-semantic-border-width-thin) solid
      var(--emu-semantic-colors-primary-50);
    position: static;

    nav {
      z-index: var(--emu-common-other-z-index-base);
      background-color: inherit;
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 24px;
      padding-right: var(--emu-common-spacing-medium);

      @include mq('large') {
        padding-right: 24px;
      }

      ul {
        display: grid;
        grid-template-columns: repeat(2, minmax(154.5px, auto));
        width: fit-content;
        column-gap: 36px;
        row-gap: var(--emu-common-spacing-small);

        @include mq('large') {
          grid-template-columns: repeat(2, auto);
        }

        li {
          width: fit-content;
          padding-top: 7px;
          padding-bottom: 7px;

          a {
            border: none;
            font-size: var(--emu-semantic-font-sizes-narrow-xs);
            line-height: normal;
            letter-spacing: 0.48px;
            padding: var(--emu-common-spacing-none);

            &:hover,
            &:active,
            &:focus,
            & {
              text-decoration: none;
              background-color: var(--emu-common-colors-transparent);
            }
          }
        }
      }
    }
  }

  &__bottom-text {
    padding-top: 18px;
    padding-right: 24px;
    padding-bottom: 18px;
    padding-left: 24px;
    font-size: var(--emu-semantic-font-sizes-narrow-xs);
    line-height: 20px;
  }
}
