[lang='pt-BR'],
[lang='pt'] {
  #home {
    .home__hero-carousel
      .home-hero-teaser
      .content-container
      .content
      .aaaem-teaser__title {
      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xl);
      }
    }
  }
  #about {
    .icons-content {
      &__items {
        &-title {
          @include mq('large') {
            min-height: 119px;
            align-items: flex-start;
          }
        }
      }
    }
  }
}
