.aaaem-accordion {
  padding-left: 24px;
  padding-right: 24px;
  /*
    Magical numbers
        24px - horizontal padding
        864px - Actual max-width
  */
  max-width: 912px; // calc(864px + 24px + 24px)
  margin-left: auto;
  margin-right: auto;

  // Spell the whole class name instead of `&__button` to increase specificity
  .aaaem-accordion__button {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    column-gap: var(--emu-common-spacing-small);

    @include mq('large') {
      // The laptop vertical padding from each accordion title
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .aaaem-accordion__icon {
      align-self: start;
      display: inline-block;
      flex-shrink: 0;
      /*
        The styles below are based on the observations.
        Might not exactly match the design as 
        the design uses an SVG but implementation uses 
        `before` and `after` pseudo classes
      */
      padding-left: var(--emu-common-spacing-xs);
      padding-top: var(--emu-common-spacing-xs);
      padding-right: var(--emu-common-spacing-xs);
      padding-bottom: var(--emu-common-spacing-xs);

      &::before {
        transform: translateY(15%) rotate(55deg);
        height: 10px;
        width: 1.5px;
      }

      &::after {
        transform: translateY(15%) rotate(-55deg);
        height: 10px;
        width: 1.5px;
      }
    }
  }

  &__title {
    font-weight: var(--emu-semantic-font-weight-500);
    letter-spacing: -0.24px;
    padding-right: var(--emu-common-spacing-xxs);

    @include mq('large') {
      line-height: var(--emu-semantic-line-heights-wide-large);
      letter-spacing: -0.4px;
    }
  }

  // Selects the direct child of the `dd` element
  .js-badger-accordion-panel-inner {
    padding-left: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    // The distance between the end of the expanded paragraph and next title
    padding-bottom: 24px;

    @include mq('large') {
      padding-bottom: 40px;
    }
  }

  // Selects the `dt` element
  &__item {
    &:not(:first-of-type) {
      border-bottom: none;
      // The divider between the previous and the current divider
      border-top: var(--emu-common-border-width-thin) solid #d7d2cb;
    }

    &:has(+ .-ba-is-active) {
      button {
        @include mq('large') {
          padding-bottom: calc(32px - 8px);
        }
      }
    }
  }

  &__panel {
    .aaaem-text {
      padding-right: 40px;
      color: var(--emu-semantic-colors-secondary-dark);
      font-family: var(--emu-semantic-font-families-body);
      font-size: 14px;
      font-weight: var(--emu-common-font-weight-regular);
      line-height: var(--emu-semantic-line-heights-narrow-small);
      letter-spacing: -0.28px;
      display: flex;
      flex-direction: column;
      row-gap: var(--emu-common-spacing-medium);

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-medium);
        font-weight: var(--emu-common-font-weight-regular);
        line-height: var(--emu-common-line-heights-wide-large);
        letter-spacing: -0.32px;
      }

      sup {
        font-size: 11px;
        font-style: normal;
        font-weight: var(--emu-common-font-weight-regular);
        line-height: 20px;
        letter-spacing: -0.32px;
      }

      .emphasis {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: var(--emu-semantic-line-heights-wide-xs);
        color: var(--emu-semantic-colors-secondary-100);
      }
    }
  }

  &__header {
    border-bottom: none;
  }
}
