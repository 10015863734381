#locator-terms-of-use {
  .section {
    &__text {
      font-size: 14px;
      line-height: var(--emu-semantic-line-heights-narrow-small);
      letter-spacing: -0.28px;
      margin-bottom: 20px;

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-wide-medium);
        line-height: var(--emu-common-line-heights-wide-medium);
        letter-spacing: -0.32px;
        margin-bottom: var(--emu-common-spacing-large);
      }

      &--mb-none {
        margin-bottom: var(--emu-common-spacing-none);
      }

      p {
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }
}