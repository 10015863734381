.clinic-popup {
	&__heading {
		margin-bottom: 7px;
		font-size: var(--emu-semantic-font-sizes-narrow-medium);
		line-height: var(--emu-semantic-line-heights-narrow-medium);
		color: var(--emu-semantic-colors-primary-dark);
		font-family: var(--emu-semantic-font-families-body);
		font-weight: var(--emu-semantic-font-weight-500);
	}

	&__description {
		margin-bottom: 20px;
		font-family: var(--emu-semantic-font-families-body);
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.5px;
		color: #58585a; //as per design
	}

	&__buttons-container {
		display: flex;
	}

	&__link {
		color: var(--emu-semantic-colors-primary-dark);
		cursor: pointer;
		display: inline-block;
		font-family: var(--emu-semantic-font-families-body);
		font-size: var(--emu-semantic-font-sizes-narrow-medium);
		font-weight: var(--emu-common-font-weight-regular);
		height: 50px;
		line-height: 33px;
		min-width: 50px;
		overflow: hidden;
		position: relative;
		text-decoration: none;
		text-overflow: ellipsis;
		transition: 0.3s;
		white-space: nowrap;

		&:visited {
			color: var(--emu-semantic-colors-primary-dark);
		}
	}


	&__link-website,
	&__link-phone {
		color: var(--emu-semantic-colors-primary-dark);
		border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-100);
		background-color: var(--emu-common-colors-white);
		border-radius: 25px;
		padding-top: var(--emu-common-spacing-small);
		padding-right: 20px;
		padding-bottom: 12px;
		padding-left: 20px;
		margin-right: 10px;
		font-weight: var(--emu-common-font-weight-regular);

		&:hover {
			background-color: rgba(0, 173, 187, 0.15);
			color: var(--emu-semantic-colors-primary-dark);
		}
	}

	&__link-clinic {
		border-radius: 50%;
		padding: var(--emu-common-spacing-small);
		margin-left: auto;
		border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-100);
		color: var(--emu-common-colors-white);

		&:hover {
			background-color: rgba(0, 173, 187, 0.15);
		}
	}

	&__link-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

//'importants' are required to overwrite google styles
.gm-style .gm-style-iw-c {
	box-shadow: 0 1px 4px 0 var(--emu-semantic-colors-secondary-50);
	padding-left: var(--emu-common-spacing-medium);
	max-width: 340px !important;

	>button {
		top: 2px !important;
		right: 7px !important;
	}
}