#safety {
  .section {
    &__text {
      ul {
        list-style-type: square;
        padding-left: 20px;

        @include mq('large') {
          padding-left: 26px;
        }

        li {
          padding-left: var(--emu-common-spacing-xs);

          @include mq('large') {
            padding-left: 6px;
          }

          &::marker {
            font-size: 9px;

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-xs);
            }
          }
        }
      }
    }
  }
}