/** 
 * This component acts as a top-level banner in About, Benefits, FAQs and Before & After pages.
 * Design - https://www.figma.com/file/ObAIo22ang41ZEvKpUw0O9/017289_ALLSKI23_Consumer_website_AW?type=design&node-id=84-1014&mode=dev
 */

.aaaem-teaser {
  .content {
    &-container {
      position: absolute;
      top: 0;
    }

    &-middle-center {
      .aaaem-teaser__title.cmp-teaser__title {
        font-size: var(--emu-semantic-font-sizes-narrow-xxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxl);
        font-weight: var(--emu-semantic-font-weight-500);
        letter-spacing: -0.36px;
        text-shadow: var(--emu-common-spacing-none) var(--emu-common-spacing-none) 20px rgba(0, 0, 0, 0.20);
        padding-top: 66px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
          line-height: var(--emu-semantic-line-heights-wide-xxl);
          letter-spacing: -0.72px;
          padding-top: 73px;
        }
      }
    }
  }

  .disclaimer-bottom-right {
    .image--disclaimer {
      p {
        font-family: var(--emu-semantic-font-families-helvetica-now-text-light);
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        line-height: 12px;
        font-weight: var(--emu-semantic-font-weight-325);
        margin-right: 24px;
        margin-bottom: 30px;
        opacity: 0.9;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: var(--emu-semantic-line-heights-wide-xs);
          margin-right: 109px;
          margin-bottom: 40px;
        }

        br {
          display: block;

          @include mq('large') {
            display: none;
          }
        }

        .emphasis {
          @include mq('large') {
            color: var(--emu-semantic-colors-secondary-300);
            opacity: 1;
          }
        }
      }
    }
  }

  // to override default clientlib breakpoint for image
  .teaser-image-container {
    .cmp-teaser__image {
      &--mobile {
        display: block;

        @include mq('large') {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include mq('large') {
          display: block;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}