header {
  position: sticky;
  top: 0;
  z-index: var(--emu-common-other-z-index-header);

  .header {
    position: static;

    &__main-content {
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      &.js-toggle-on {
        > .button {
          &:nth-child(4) {
            display: none;
          }

          &:nth-child(5) {
            display: initial;
          }

          @include mq('large') {
            &:nth-child(4),
            &:nth-child(5) {
              display: none;
            }
          }
        }

        .emu-navigation__content-wrapper {
          transform: translateX(0);
          transition-timing-function: ease-in;

          @include mq('large') {
            transform: none;
          }
        }
      }

      > .button {
        // find a clinic button
        &:nth-child(3) {
          position: fixed;
          bottom: 24px;
          right: 24px;

          @include mq('large') {
            position: static;
          }

          .emu-button {
            width: 48px;
            height: 48px;
            border-radius: var(--emu-common-border-radius-xs);
            justify-content: center;
            align-items: center;

            @include mq('large') {
              width: auto;
              height: auto;
              padding-top: var(--emu-common-spacing-small);
              padding-right: 10px;
              padding-bottom: var(--emu-common-spacing-small);
              padding-left: 10px;
              border-radius: var(--emu-common-border-radius-xxs);
              gap: 10px;
            }

            &,
            &:hover,
            &:focus,
            &:active {
              border-color: var(--emu-semantic-colors-primary-dark);
              background-color: var(--emu-semantic-colors-primary-dark);
              outline: none;

              @include mq('large') {
                border-color: var(--emu-common-colors-white);
                background-color: var(--emu-common-colors-transparent);
              }
            }

            img {
              margin: var(--emu-common-spacing-none);

              @include mq('large') {
                width: 24px;
              }
            }

            span {
              display: none;
              line-height: normal;
              letter-spacing: 0.56px;
              font-size: 14px;
              font-weight: 400;

              @include mq('large') {
                display: initial;
              }
            }
          }

          @include mq('large') {
            display: block;
          }
        }

        // 4th child - open menu button
        // 5th child - close menu button
        &:nth-child(4),
        &:nth-child(5) {
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);
          width: var(--emu-common-sizing-medium);
          height: var(--emu-common-sizing-medium);
          z-index: var(
            --emu-common-other-z-index-cookie-banner
          ); // more than header z-index

          @include mq('large') {
            display: none;
          }
        }

        &:nth-child(5) {
          display: none;
        }
      }
    }

    &__company-logo {
      position: relative;
      z-index: var(
        --emu-common-other-z-index-cookie-banner
      ); // more than header z-index

      img {
        width: auto;
        height: var(--emu-common-sizing-medium);

        @include mq('large') {
          height: 44px;
        }
      }
    }

    &__menu-trigger {
      // need this for increasing specificity
      &.emu-button-v2.emu-button.header__menu-trigger {
        width: var(--emu-common-sizing-medium);
        height: var(--emu-common-sizing-medium);
        padding: var(--emu-common-spacing-none);
        justify-content: center;
        align-items: center;

        &,
        &:hover,
        &:active,
        &:focus {
          background-color: var(--emu-common-colors-transparent);
          outline: none;
          -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      &#header__menu-close-trigger {
        display: block;

        @include mq('large') {
          display: none;
        }
      }
    }
  }
}
